import bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    position: relative;
    overflow: hidden;
    padding-bottom: ${(props) => (props.extraSpacing ? '0.22em' : 0)};
    &:not(:first-child) {
        margin-top: ${(props) => (props.extraSpacing ? '-0.22em' : 0)};
    }
`

const Placeholder = styled.div`
    visibility: hidden;
    opacity: 0;
`

const Current = styled.div`
    position: absolute;
    top: ${(props) => (props.extraSpacing ? '0.14em' : 0)};
    left: 0;
    opacity: 1;
    transform: translateY(0);
    ${(props) =>
        props.isEntering &&
        `
        opacity: 0;
        transform: translateY(100%);
        transition: all 0.6s;
        transition-timing-function: ease-in-out;
    `};
`

const New = styled.div`
    position: absolute;
    top: ${(props) => (props.extraSpacing ? '0.14em' : 0)};
    left: 0;
    opacity: 0;
    transform: translateY(-100%);
    ${(props) =>
        props.isEntering &&
        `
        opacity: 1;
        transform: translateY(0);
        transition: all 0.6s;
        transition-timing-function: ease-in-out;
    `};
`

const propTypes = {
    text: PropTypes.string.isRequired,
    delay: PropTypes.number,
    stagger: PropTypes.number,
    extraSpacing: PropTypes.bool,
}

class AnimateText extends React.Component {
    state = {
        currentText: null,
        newText: '&nbsp;',
        isTransitioning: null,
    }

    constructor(props) {
        super(props)
        bindAll(this, ['startTransitioning', 'stopTransitioning'])
    }

    componentDidMount() {
        this.componentUpdate(this.props)
    }

    componentWillUpdate(nextProps) {
        this.componentUpdate(nextProps)
    }

    delay() {
        const delay = this.props.delay || 100
        const stagger = this.props.stagger || 1
        return delay * stagger
    }

    componentUpdate(props) {
        if (props.text !== this.state.newText) {
            this.startTimer = setTimeout(this.startTransitioning, this.delay())
            this.stopTimer = setTimeout(this.stopTransitioning, 600 + this.delay())
        }
    }

    componentWillUnmount() {
        clearTimeout(this.startTimer)
        clearTimeout(this.stopTimer)
    }

    startTransitioning() {
        this.setState({
            newText: this.props.text,
            isEntering: true,
        })
    }

    stopTransitioning() {
        this.setState({
            currentText: this.state.newText,
            isEntering: false,
        })
    }

    render() {
        return (
            <Container extraSpacing={this.props.extraSpacing}>
                <Placeholder>{this.state.newText}</Placeholder>
                <Current extraSpacing={this.props.extraSpacing} isEntering={this.state.isEntering}>
                    {this.state.currentText}
                </Current>
                <New extraSpacing={this.props.extraSpacing} isEntering={this.state.isEntering}>
                    {this.state.newText}
                </New>
            </Container>
        )
    }
}

AnimateText.propTypes = propTypes

export default AnimateText
