import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { paths } from '../config'
import { languagePath, title } from '../helpers'
import { font, desktop, tablet, cols } from '../styles'

import AnimateText from './AnimateText'

const Container = styled.div`
    overflow-x: hidden;
    margin-top: 9vh;
    margin-bottom: 18vh;
    padding: 0 24px;
    ${tablet`
        width: ${cols(6)};
        margin-left: ${cols(2, 72)};
        margin-top: 50vh;
        margin-bottom: 50vh;
        padding: 0;
        position: relative;
    `};
    ${desktop`
        width: ${cols(5)};
    `};
`

const Heading = styled.h1`
    font-family: ${font.heading};
    font-size: ${font.large};
    line-height: 1.2;
    margin-bottom: 24px;
    pointer-events: none;
    ${tablet`
        font-size: ${font.largest};
        line-height: 1;
        position: fixed;
        top: 50vh;
        left: ${cols(5, 72)};
        width: ${cols(3)};
        margin-top: -12px;
        margin-bottom: 0;
    `};
    ${desktop`
        font-size: ${font.huge};
        line-height: 1;
        margin-top: -18px;
    `};
`

const Nav = styled.div`
    font-family: ${font.heading};
    min-width: 100%;
    ${desktop`
        &:hover {
            a {
                opacity: 0.2;
            }
        }
    `};
`

const NavItem = styled.div`
    font-size: ${font.medium};
    ${tablet`
        font-size: ${font.medium};
    `};
    ${desktop`
        font-size: ${font.large};
    `};
    a {
        white-space: nowrap;
        text-decoration: none;
        display: block;
        padding: 0 0 12px;
        transition: color 0.6s, opacity 0.15s;
        ${tablet`
            &:hover {
                opacity: 1;
            }
            &:active {
                opacity: 0.7;
                transition: none;
            }
        `};
        ${desktop`
            padding-bottom: 58px;
        `};
    }
`

const Works = ({ language, data }) => {
    const { work } = data

    const strings = {
        title: { en: 'Work', et: 'Tööd' },
        headingFirst: { en: 'Selected', et: 'Valitud' },
        headingSecond: { en: 'works.', et: 'tööd.' },
    }

    let s = 1
    const stagger = () => s++

    return (
        <Container>
            <Helmet>
                <title>{title(strings.title[language])}</title>
            </Helmet>
            <Heading>
                <AnimateText extraSpacing text={strings.headingFirst[language]} stagger={stagger()} />
                <AnimateText extraSpacing text={strings.headingSecond[language]} stagger={stagger()} />
            </Heading>
            <Nav>
                {work.map((work) => (
                    <NavItem key={work.name}>
                        <Link to={languagePath(language, `${paths.work}/${work.name}`)}>
                            <AnimateText text={work.title[language]} stagger={stagger()} />
                        </Link>
                    </NavItem>
                ))}
            </Nav>
        </Container>
    )
}

Works.propTypes = {
    data: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
}

export default Works
