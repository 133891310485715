import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { font, desktop } from '../styles'

const Main = styled.div`
    display: none;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    /* top: ${({ y }) => `${String(y)}px`};
    left: ${({ x }) => `${String(x)}px`}; */
    /* opacity: 0.2; */
    margin-left: -36px;
    margin-top: -48px;
    transform: translate(${({ x }) => `${String(x)}px`}, ${({ y }) => `${String(y)}px`})
        ${({ isReversed }) => isReversed && 'scaleX(-1)'};
    /* transition: transform 0.15s; */
    cursor: pointer;
    ${desktop`
        display: block;
    `};
    &:before {
        content: '→';
        color: ${({ color }) => (color ? color : 'inherit')};
        font-family: ${font.heading};
        font-size: ${font.huge};
        display: block;
    }
`

const Pointer = (props) => (
    <Main color={props.color} x={props.x} y={props.y} isReversed={props.isReversed} onClick={props.handleClick} />
)

Pointer.propTypes = {
    color: PropTypes.string,
    handleClick: PropTypes.func.isRequired,
    isReversed: PropTypes.bool,
    x: PropTypes.number,
    y: PropTypes.number,
}

export default Pointer
