import _bindAll from 'lodash/bindAll'
import _find from 'lodash/find'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { paths } from '../config'
import { KEY_LEFT_ARROW, KEY_RIGHT_ARROW } from '../constants'
import { hexToRgbA, languagePath } from '../helpers'
import { cols, tablet, desktop } from '../styles'

import Pointer from './Pointer'
import Work from './Work'
import Next from './Next'

const Container = styled.div`
    overflow-x: hidden;
    ${tablet`
        width: ${cols(6)};
        margin-left: ${cols(2, 72)};
    `};
    ${desktop`
        width: ${cols(5)};
    `};
`

const Progress = styled.div`
    position: fixed;
    z-index: 1100;
    right: 24px;
    top: 0;
    width: calc(100% - 48px);
    height: 2px;
    background: ${({ color }) => (color ? hexToRgbA(color, 0.2) : 'none')};
    ${tablet`
        width: ${cols(6)};
        right: 72px;
        height: 4px;
    `};
`

const Indicator = styled.div`
    position: absolute;
    top: 0;
    left: ${({ total, position }) => `${(100 / total) * position}%`};
    width: ${({ total }) => `${100 / total}%`};
    height: 100%;
    background: ${({ color }) => color};
    transition: all 0.3s;
    transition-timing-function: ease-in-out;
`

class WorkContainer extends React.Component {
    state = {
        currentWork: null,
        shouldRedirectTo: null,
        shouldDisplayPointer: null,
        mouseX: 100,
        mouseY: 2000,
        mouseOnRightSide: null,
    }

    constructor() {
        super()
        _bindAll(this, [
            'handleKeyPress',
            'handleMouseMove',
            'handleMouseEnter',
            'handleMouseLeave',
            'next',
            'prev',
            'handleNav',
        ])
    }

    static getDerivedStateFromProps(nextProps) {
        const { workName } = nextProps.match.params
        const currentWork = _find(nextProps.data.work, { name: workName })
        return {
            currentWork,
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress)
    }

    handleKeyPress(e) {
        switch (e.keyCode) {
            case KEY_RIGHT_ARROW:
                this.next()
                break
            case KEY_LEFT_ARROW:
                this.prev()
                break
            default:
                return
        }
    }

    handleMouseMove(e) {
        this.setState({
            mouseX: e.clientX,
            mouseY: e.clientY,
            mouseOnRightSide: e.clientX > window.innerWidth / 2.2,
            shouldDisplayPointer: true,
        })
    }

    handleMouseEnter(e) {
        this.setState({
            shouldDisplayPointer: true,
        })
    }

    handleMouseLeave(e) {
        this.setState({
            shouldDisplayPointer: false,
        })
    }

    next() {
        const { work } = this.props.data
        const index = work.indexOf(this.state.currentWork)
        const newIndex = work.length - 1 > index ? index + 1 : 0
        this.goTo(work[newIndex].name)
    }

    prev() {
        const { work } = this.props.data
        const index = work.indexOf(this.state.currentWork)
        const newIndex = index > 0 ? index - 1 : work.length - 1
        this.goTo(work[newIndex].name)
    }

    goTo(workName) {
        this.props.history.push(languagePath(this.props.language, paths.work + '/' + workName))
    }

    handleNav() {
        if (this.state.mouseOnRightSide) {
            this.next()
        } else {
            this.prev()
        }
    }

    render() {
        const { data, language, history } = this.props
        const { work } = data
        const { currentWork, shouldDisplayPointer, mouseX, mouseY, mouseOnRightSide } = this.state
        const total = work.length
        const position = work.indexOf(currentWork)

        return (
            <Container
                onMouseMove={this.handleMouseMove}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                {currentWork && (
                    <React.Fragment>
                        <Work work={currentWork} language={language} key={history.location.pathname} />
                        <Progress color={currentWork.color}>
                            <Indicator position={position} total={total} color={currentWork.color} />
                        </Progress>
                        {shouldDisplayPointer && (
                            <Pointer
                                color={currentWork.color}
                                x={mouseX}
                                y={mouseY}
                                isReversed={!mouseOnRightSide}
                                handleClick={this.handleNav}
                            />
                        )}
                        <Next color={currentWork.color} handleClick={this.next} />
                    </React.Fragment>
                )}
            </Container>
        )
    }
}

WorkContainer.propTypes = {
    data: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
}

export default withRouter(WorkContainer)
