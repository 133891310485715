import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import AnimateText from './AnimateText'
import { Slide } from './Studio'

import { cols, font, desktop, tablet, desktopLarge } from '../styles'

const Heading = styled.h1`
    font-family: ${font.heading};
    font-size: ${font.large};
    line-height: 1.2;
    margin-bottom: 24px;
    ${tablet`
        font-size: ${font.largest};
        line-height: 1;
        position: absolute;
        top: 50vh;
        left: ${cols(3)};
        width: ${cols(3)};
        margin-top: -12px;
        margin-bottom: 0;
    `};
    ${desktop`
        font-size: ${font.huge};
        line-height: 1;
        margin-top: -18px;
    `};
`

const Tagline = styled.h2`
    font-size: ${font.normal};
    line-height: 1.5;
    margin-bottom: 24px;
    ${tablet`
        font-size: ${font.normal};
        line-height: 1.5;
        position: absolute;
        top: 50vh;
        left: 0;
        width: ${cols(3)};
        margin-bottom: 0;
    `};
    ${desktop`
        font-size: ${font.medium};
    `};
    ${desktopLarge`
        font-size: ${font.large};
        line-height: 1.3;
    `};
`

const Body = styled.h3`
    font-size: ${font.normal};
    line-height: 1.5;
    width: 80%;
    ${tablet`
        font-size: ${font.normal};
        line-height: 1.5;
        position: absolute;
        left: 0;
        bottom: 9vh;
        width: ${cols(3)};
        margin-bottom: -5px;
        padding-right: 4vw;
    `};
    ${desktop`
        font-size: ${font.medium};
        width: ${cols(4)};
    `};
`

class StudioAbout extends React.Component {
    strings = {
        headingFirst: { en: 'About', et: 'Kes me' },
        headingSecond: { en: 'us.', et: 'oleme.' },
        taglineFirst: { en: 'A digital-first design studio', et: 'Oleme digitaalse ja graafilise' },
        taglineSecond: { en: 'based in Tallinn, Estonia.', et: 'disaini stuudio.' },
        body: {
            en: 'We make technology and design work for your business.',
            et: 'Paneme tehnoloogia ja disaini sinu organisatsiooni heaks tööle.',
        },
    }

    render() {
        const { language } = this.props

        let s = 1
        const stagger = () => s++

        return (
            <Slide>
                <Heading>
                    <AnimateText extraSpacing text={this.strings.headingFirst[language]} stagger={stagger()} />
                    <AnimateText extraSpacing text={this.strings.headingSecond[language]} stagger={stagger()} />
                </Heading>
                <Tagline>
                    <AnimateText text={this.strings.taglineFirst[language]} stagger={stagger()} />
                    <AnimateText text={this.strings.taglineSecond[language]} stagger={stagger()} />
                </Tagline>
                <Body>
                    <AnimateText text={this.strings.body[language]} stagger={stagger()} />
                </Body>
            </Slide>
        )
    }
}

StudioAbout.propTypes = {
    language: PropTypes.string.isRequired,
}

export default StudioAbout
