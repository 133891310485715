import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { tablet } from '../styles'

const Container = styled.div`
    position: fixed;
    left: 50%;
    bottom: 12px;
    z-index: 11000;
    height: 72px;
    width: 72px;
    margin-left: -36px;
    transition: opacity 0.3s;
    cursor: pointer;
    ${tablet`
        display: none;
    `};
`

const Line = styled.div`
    position: absolute;
    top: 0;
    left: 18px;
    width: 36px;
    height: 2px;
    transition: background 0.6s, transform 0.15s, opacity 0.15s;
    background: ${({ color }) => color};
    &:nth-child(1) {
        top: 24px;
        opacity: ${({ isMobileNavOpen }) => (isMobileNavOpen ? 0 : 1)};
        transform: ${({ isMobileNavOpen }) => (isMobileNavOpen ? 'translateY(-18px)' : 'none')};
    }
    &:nth-child(2) {
        top: 36px;
        transform: ${({ isMobileNavOpen }) => (isMobileNavOpen ? 'rotate(45deg)' : 'none')};
    }
    &:nth-child(3) {
        top: 36px;
        transform: ${({ isMobileNavOpen }) => (isMobileNavOpen ? 'rotate(-45deg)' : 'none')};
    }
    &:nth-child(4) {
        top: 48px;
        opacity: ${({ isMobileNavOpen }) => (isMobileNavOpen ? 0 : 1)};
        transform: ${({ isMobileNavOpen }) => (isMobileNavOpen ? 'translateY(18px)' : 'none')};
    }
`

const SidebarToggle = ({ toggleHandler, foregroundColor, backgroundColor, isMobileNavOpen }) => (
    <Container foregroundColor={foregroundColor} backgroundColor={backgroundColor} onClick={toggleHandler}>
        <Line color={isMobileNavOpen ? foregroundColor : backgroundColor} isMobileNavOpen={isMobileNavOpen} />
        <Line color={isMobileNavOpen ? foregroundColor : backgroundColor} isMobileNavOpen={isMobileNavOpen} />
        <Line color={isMobileNavOpen ? foregroundColor : backgroundColor} isMobileNavOpen={isMobileNavOpen} />
        <Line color={isMobileNavOpen ? foregroundColor : backgroundColor} isMobileNavOpen={isMobileNavOpen} />
    </Container>
)

SidebarToggle.propTypes = {
    backgroundColor: PropTypes.string.isRequired,
    foregroundColor: PropTypes.string.isRequired,
    isMobileNavOpen: PropTypes.bool.isRequired,
    toggleHandler: PropTypes.func.isRequired,
}

export default SidebarToggle
