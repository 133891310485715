import StudioAbout from '../components/StudioAbout'
import StudioClients from '../components/StudioClients'
// import StudioCTA from '../components/StudioCTA'
// import StudioPeople from '../components/StudioPeople'
import StudioServices from '../components/StudioServices'

import { paths } from '../config'

import image01 from '../assets/studio-lounge.jpg'
import image02 from '../assets/studio-kitchen.jpg'
import image03 from '../assets/studio-up2.jpg'
// import image04 from '../assets/studio-people.jpg'
// import image05 from '../assets/studio-up.jpg'
// import image06 from '../assets/namecards.jpg'

const about = [
    { path: paths.studio, name: 'studio', title: 'Studio', component: StudioAbout, backgroundImage: image01 },
    { path: paths.services, name: 'services', title: 'Services', component: StudioServices, backgroundImage: image02 },
    { path: paths.clients, name: 'clients', title: 'Clients', component: StudioClients, backgroundImage: image03 },
    // { path: paths.people, name: 'people', title: 'People', component: StudioPeople, backgroundImage: image04 },
    // {
    //     path: paths.getInTouch,
    //     name: 'get-in-touch',
    //     title: 'Get in touch',
    //     component: StudioCTA,
    //     backgroundImage: image06,
    // },
]

export default about
