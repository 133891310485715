import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import 'normalize.css'
import './styles.css'

import data from './data'

import Main from './components/Main'
import ScrollToTop from './components/ScrollToTop'

const theme = {
    breakpoints: {
        mobile: 0, // targeting all devices
        tablet: 737, // targeting devices that are larger than the iPhone 6 Plus (which is 736px in landscape mode)
        desktop: 1025, // targeting devices that are larger than the iPad (which is 1024px in landscape mode)
        large: 1620,
    },
}

ReactDOM.render(
    <Router>
        <ThemeProvider theme={theme}>
            <ScrollToTop>
                <Main data={data} />
            </ScrollToTop>
        </ThemeProvider>
    </Router>,
    document.getElementById('root')
)
