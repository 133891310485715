import _get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

import { title, metaTags } from '../helpers'
import { cols, font, tablet, desktop } from '../styles'

import AnimateText from './AnimateText'
import WorkContentBackground from './WorkContentBackground'
import WorkContentImage from './WorkContentImage'
import WorkContentVideo from './WorkContentVideo'

const Container = styled.div`
    color: ${({ color }) => color};
    position: relative;
    padding: 24px;
    padding-top: 9vh;
    ${tablet`
        padding: 0;
        padding-top: 88vh;
    `};
    a {
        color: ${({ color }) => color};
        text-decoration: none;
    }
`

const Heading = styled.h1`
    position: relative;
    z-index: 1;
    font-family: ${font.heading};
    font-size: ${font.large};
    margin-bottom: 24px;
    ${tablet`
        font-size: ${font.largest};
        letter-spacing: -2px;
        line-height: 1.1;
        position: absolute;
        top: 50vh;
        left: 0;
        width: ${cols(4)};
        margin-top: -12px;
        margin-bottom: 0;
    `};
    ${desktop`
        font-size: ${font.huge};
        line-height: 1;
        width: ${({ noWrap }) => cols(noWrap ? 4 : 3)};
        margin-top: -18px;
    `};
`

const Description = styled.h2`
    position: relative;
    z-index: 1;
    font-size: ${font.small};
    line-height: 1.5;
    margin-bottom: 24px;
    width: 80%;
    ${tablet`
        font-size: ${font.medium};
        line-height: 1.4;
        position: absolute;
        top: 9vh;
        left: 0;
        width: ${cols(5)};
        margin: 0;
    `};
    ${desktop`
        font-size: ${font.large};
        line-height: 1.3;
    `};
`

const Meta = styled.div`
    font-size: ${font.small};
    line-height: 1.5;
    margin-bottom: 48px;
    display: flex;
    ${tablet`
        font-size: ${font.normal};
        line-height: 1.5;
        display: block;
        position: absolute;
        left: ${cols(4)};
        top: 50vh;
        width: ${cols(2)};
        margin: 0;
    `};
    ${desktop`
        font-size: ${font.medium};
        line-height: 1.4;
        width: ${cols(1)};
    `};
`

const Role = styled.div`
    position: relative;
    z-index: 1;
    flex: 0 0 50%;
`

const RoleItem = styled.div``

const Website = styled.div`
    flex: 0 0 50%;
    position: relative;
    z-index: 11000;
    ${tablet`
        margin-top: 24px;
        &:hover {
            opacity: 0.2;
        }
    `};
`

const WebsiteLink = styled.a``

const AwardsContainer = styled.div`
    position: relative;
    z-index: 1;
    ${tablet`
        position: fixed;
        right: 72px;
        bottom: 9vh;
        width: 100vh;
    `};
`

const Awards = styled.div`
    position: relative;
    z-index: 1;
    font-size: ${font.small};
    line-height: 1.5;
    margin-bottom: 24px;
    ${tablet`
        font-size: ${font.normal};
        position: relative;
        margin: 0;
        transform: rotate(-90deg) translateX(100%);
        transform-origin: bottom right;
    `};
    ${desktop`
        font-size: ${font.medium};
    `};
`

const Content = styled.div`
    position: relative;
    z-index: 1;
    opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
    transition: opacity 0.6s;
`

const ContentWrapper = styled.div``

const Background = styled.div`
    position: fixed;
    z-index: 0;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
`

const CONTENT_TYPE_IMAGE = 'image'
const CONTENT_TYPE_VIDEO = 'video'
const CONTENT_TYPE_BACKGROUND = 'background'

class Work extends React.Component {
    strings = {
        visitWebsite: { en: 'Visit website', et: 'Ava veeb' },
    }

    state = {
        shouldDisplayContent: false,
    }

    componentDidMount() {
        this.timeout = setTimeout(() => this.setState({ shouldDisplayContent: true }), 2000)
    }

    componentWillUnmount() {
        clearTimeout(this.timeout)
    }

    render() {
        const { work, language } = this.props

        let s = 1
        const stagger = () => s++

        return (
            <Container color={work.color}>
                <Helmet>
                    {metaTags({
                        language,
                        title: title(work.title[language]),
                        description: work.description[language],
                        themeColor: work.backgroundColor,
                    })}
                </Helmet>
                <Heading noWrap={_get(work, ['titleNoWrap'], false)}>
                    <AnimateText extraSpacing text={work.title[language]} stagger={stagger()} />
                </Heading>
                {work.description && (
                    <Description>
                        <AnimateText text={work.description[language]} stagger={stagger()} />
                    </Description>
                )}
                {work.awards && work.awards.length > 0 && (
                    <AwardsContainer>
                        <Awards>
                            {_get(work, ['awards'], []).map((award, i) => (
                                <AnimateText key={i} text={award} stagger={stagger()} />
                            ))}
                        </Awards>
                    </AwardsContainer>
                )}
                {(work.role || work.url) && (
                    <Meta>
                        {work.role && (
                            <Role>
                                {_get(work, ['role'], []).map((item, i) => (
                                    <RoleItem key={i}>
                                        <AnimateText text={item} stagger={stagger()} />
                                    </RoleItem>
                                ))}
                            </Role>
                        )}
                        {work.url && (
                            <Website>
                                <WebsiteLink href={work.url} target="_blank" rel="noopener">
                                    <AnimateText text={this.strings.visitWebsite[language]} stagger={stagger()} />
                                </WebsiteLink>
                            </Website>
                        )}
                    </Meta>
                )}
                {work.content && (
                    <Content isHidden={!this.state.shouldDisplayContent}>
                        {work.content.map((element, i) => {
                            switch (element.type) {
                                case CONTENT_TYPE_IMAGE:
                                    return (
                                        <ContentWrapper key={work.name + element.id}>
                                            <WorkContentImage
                                                data={element.data}
                                                color={work.color}
                                                background={work.backgroundColor}
                                            />
                                        </ContentWrapper>
                                    )
                                case CONTENT_TYPE_VIDEO:
                                    return (
                                        <ContentWrapper key={work.name + element.id}>
                                            <WorkContentVideo
                                                data={element.data}
                                                color={work.color}
                                                background={work.backgroundColor}
                                            />
                                        </ContentWrapper>
                                    )
                                case CONTENT_TYPE_BACKGROUND:
                                    return (
                                        <WorkContentBackground
                                            key={work.name + element.id}
                                            data={element.data}
                                            background={work.backgroundColor}
                                        />
                                    )
                                default:
                                    return null
                            }
                        })}
                    </Content>
                )}
                {work.background && (
                    <Background>
                        {work.background.map((element, i) => {
                            switch (element.type) {
                                case CONTENT_TYPE_BACKGROUND:
                                    return (
                                        <WorkContentBackground
                                            key={work.name + element.id}
                                            data={element.data}
                                            background={work.backgroundColor}
                                        />
                                    )
                                default:
                                    return null
                            }
                        })}
                    </Background>
                )}
            </Container>
        )
    }
}

Work.propTypes = {
    work: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
}

export default Work
