const work = [
    {
        color: '#000000',
        backgroundColor: '#DBDBDB',
        name: 'tolm',
        title: { en: 'Tolm', et: 'Tolm' },
        description: {
            en: 'A motion design and animation directing duo.',
            et: 'Animatsiooniduo.',
        },
        url: 'https://tolm.tv',
        role: ['Web design', 'Development'],
        awards: [],
        content: [
            { id: 1, type: 'image', data: { filename: 'tolm-1.jpg' } },
            { id: 2, type: 'image', data: { filename: 'tolm-2.jpg' } },
            { id: 3, type: 'image', data: { filename: 'tolm-3.jpg' } },
            { id: 4, type: 'image', data: { filename: 'tolm-4.jpg' } },
        ],
    },
    {
        color: '#E5123E',
        backgroundColor: '#FFFFFF',
        name: 'Noblessner',
        title: { en: 'Noblessner', et: 'Noblessner' },
        description: {
            en: 'A seafront quarter and harbour in Tallinn.',
            et: 'Merele avatud sadamalinnak.',
        },
        url: 'https://noblessner.ee',
        role: ['Web design', 'Development'],
        awards: [],
        content: [
            { id: 1, type: 'image', data: { filename: 'noblessner-1.jpg' } },
            { id: 2, type: 'image', data: { filename: 'noblessner-2.jpg' } },
        ],
    },
    {
        color: '#835cf2',
        backgroundColor: '#ffdbc2',
        name: 'hitsa',
        title: { en: 'Education technology compass', et: 'Hariduse tehnoloogia­kompass' },
        titleNoWrap: true,
        description: {
            en: 'Trends in technology linked to education.',
            et: 'Ülevaade haridust puudutavatest tehnoloogiatrendidest.',
        },
        url: 'https://kompass.hitsa.ee',
        role: ['Web design', 'Development', 'Illustrations'],
        awards: ['Won Bronze Egg 2019'],
        content: [
            { id: 1, type: 'image', data: { filename: 'hitsa-device.png' } },
            { id: 2, type: 'image', data: { filename: 'hitsa-illustrations.png' } },
            { id: 3, type: 'image', data: { filename: 'hitsa-sections.jpg' } },
            { id: 4, type: 'image', data: { filename: 'hitsa-infographics.jpg' } },
            { id: 5, type: 'video', data: { filename: 'hitsa.mp4', ratio: 56.77 } },
        ],
        background: [
            { id: 1, type: 'background', data: { filename: 'hitsa-bg-top.gif', position: 'top' } },
            { id: 2, type: 'background', data: { filename: 'hitsa-bg-bottom.gif', position: 'bottom' } },
        ],
    },
    {
        color: '#26474E',
        backgroundColor: '#D3EDE2',
        name: 'veriff',
        title: { en: 'Veriff', et: 'Veriff' },
        description: {
            en: 'Online identity verification with superpowers.',
            et: 'Turvaline veebiteenus klientide identiteedi verifitseerimiseks.',
        },
        url: 'https://veriff.com',
        role: ['Web design', 'Development', 'Illustrations'],
        awards: [],
        content: [
            { id: 1, type: 'image', data: { filename: 'veriff-device-orange.png' } },
            { id: 2, type: 'video', data: { filename: 'veriff.mp4' } },
            { id: 3, type: 'image', data: { filename: 'veriff-home.jpg' } },
            { id: 4, type: 'image', data: { filename: 'veriff-company.jpg' } },
            { id: 5, type: 'image', data: { filename: 'veriff-illustrations.jpg' } },
            { id: 6, type: 'image', data: { filename: 'veriff-case-studies.jpg' } },
            { id: 7, type: 'image', data: { filename: 'veriff-illustration-global.png' } },
            { id: 8, type: 'image', data: { filename: 'veriff-illustration-dev.png' } },
            { id: 9, type: 'image', data: { filename: 'veriff-illustration-platform.png' } },
            { id: 10, type: 'image', data: { filename: 'veriff-illustration-seamless.png' } },
        ],
    },
    {
        color: '#FB2424',
        backgroundColor: '#FFFFFF',
        name: 'arvo-part',
        title: { en: 'Arvo Pärt Centre', et: 'Arvo Pärdi Keskus' },
        description: {
            en: "Arvo Pärt's personal archive with an information and music centre.",
            et: 'Helilooja isikuarhiiv ning loominguline kohtumispaik.',
        },
        role: ['Web design', 'Development', 'Archive UI/UX'],
        awards: [],
        url: 'https://www.arvopart.ee',
        content: [
            { id: 1, type: 'image', data: { filename: 'arvo-part-device.jpg' } },
            { id: 2, type: 'video', data: { filename: 'arvo-part.mp4', ratio: 75 } },
            { id: 3, type: 'image', data: { filename: 'arvo-part-1.png' } },
            { id: 4, type: 'image', data: { filename: 'arvo-part-teos.png' } },
            { id: 5, type: 'image', data: { filename: 'arvo-pardi-keskus-plaan.png' } },
            { id: 6, type: 'image', data: { filename: 'arvo-part-ajatelg.png' } },
            { id: 7, type: 'image', data: { filename: 'arvo-pardi-keskus-tutvustus.png' } },
            // { id: 6, type: 'image', data: { filename: 'arvo-part-3.png' } },
            // { id: 6, type: 'image', data: { filename: 'arvo-part-4.png' } },
            // { id: 7, type: 'image', data: { filename: 'arvo-part-5.png' } },
        ],
    },
    {
        color: '#272C6F',
        backgroundColor: '#D6D4FF',
        name: 'tutorid',
        title: { en: 'Tutor.id', et: 'Tutor.id' },
        description: {
            en: 'Where learners find tutors & tutors build businesses.',
            et: 'Veebiteenus eraõpetajatele ja õpilastele.',
        },
        url: 'https://tutor.id',
        role: ['Web design', 'UI/UX', 'React'],
        awards: [],
        content: [
            { id: 1, type: 'image', data: { filename: 'tutorid-home.jpg' } },
            { id: 2, type: 'image', data: { filename: 'tutorid-illustrations.jpg' } },
            { id: 3, type: 'image', data: { filename: 'tutorid-tutor.jpg' } },
            { id: 4, type: 'image', data: { filename: 'tutorid-student.jpg' } },
            { id: 5, type: 'image', data: { filename: 'tutorid-find.jpg' } },
            { id: 6, type: 'image', data: { filename: 'tutorid-app-calendar.jpg' } },
            { id: 7, type: 'image', data: { filename: 'tutorid-app-booking.jpg' } },
        ],
        background: [
            { id: 1, type: 'background', data: { filename: 'tutorid-bg-top.gif', position: 'top' } },
            { id: 2, type: 'background', data: { filename: 'tutorid-bg-bottom.gif', position: 'bottom' } },
        ],
    },
    {
        color: '#FFFFFF',
        backgroundColor: '#3A71FF',
        name: 'ajujaht',
        title: { en: 'Ajujaht', et: 'Ajujaht' },
        description: {
            en: 'Largest competition of business ideas in Estonia.',
            et: 'Eesti suurim äriideede konkurss.',
        },
        url: 'http://ajujaht.ee',
        role: ['Visual identity', 'Web design', 'Development'],
        awards: [
            'Won Golden Egg 2016',
            'Won Gold Estonian Design Awards 2015',
            'Won Silver Estonian Design Awards 2015',
        ],
        content: [
            { id: 1, type: 'image', data: { filename: 'ajujaht-device.png' } },
            { id: 2, type: 'image', data: { filename: 'ajujaht-1.png' } },
            { id: 3, type: 'image', data: { filename: 'ajujaht-2.png' } },
            { id: 4, type: 'image', data: { filename: 'ajujaht-3.png' } },
            { id: 5, type: 'image', data: { filename: 'ajujaht-5.png' } },
            { id: 6, type: 'image', data: { filename: 'ajujaht-cvi-1.jpg' } },
            { id: 7, type: 'image', data: { filename: 'ajujaht-cvi-3.png' } },
            { id: 8, type: 'image', data: { filename: 'ajujaht-cvi-4.png' } },
            { id: 9, type: 'video', data: { filename: 'ajujaht.mp4' } },
        ],
    },
    {
        color: '#DCC6B5',
        backgroundColor: '#2F2F2F',
        name: 'craftory',
        title: { en: 'Craftory', et: 'Craftory' },
        description: { en: 'Estonian craft leather goods.', et: 'Eestis disainitud ja valmistatud nahktooted.' },
        url: 'https://craftory.com',
        role: ['Web design', 'Shopify', 'React'],
        awards: ['Won Gold Estonian Design Awards 2016', 'Won Golden Egg 2016'],
        content: [
            { id: 1, type: 'image', data: { filename: 'craftory-1.jpg' } },
            { id: 2, type: 'image', data: { filename: 'craftory-3.jpg' } },
            { id: 3, type: 'image', data: { filename: 'craftory-2.jpg' } },
            { id: 4, type: 'background', data: { filename: 'craftory-bg-1.jpg' } },
            { id: 5, type: 'image', data: { filename: 'craftory.gif' } },
            { id: 6, type: 'image', data: { filename: 'craftory-4.jpg' } },
            { id: 7, type: 'image', data: { filename: 'craftory-6.jpg' } },
            { id: 8, type: 'image', data: { filename: 'craftory-7.jpg' } },
            { id: 9, type: 'image', data: { filename: 'craftory-5.jpg' } },
            { id: 10, type: 'image', data: { filename: 'craftory-8.jpg' } },
        ],
    },
    {
        color: '#ee199b',
        backgroundColor: '#4500ab',
        name: 'jolos',
        title: { en: 'Jolos', et: 'Jolos' },
        description: { en: 'A live-communication agency.', et: 'Live-communication agentuur.' },
        role: ['Visual identity', 'Web design', 'Development'],
        awards: ['Won Bronze Estonian Design Awards 2016'],
        url: 'https://jolos.eu',
        content: [
            { id: 1, type: 'image', data: { filename: 'jolos-1.jpg' } },
            { id: 2, type: 'image', data: { filename: 'jolos-2.jpg' } },
            { id: 3, type: 'image', data: { filename: 'jolos-3.jpg' } },
            { id: 4, type: 'image', data: { filename: 'jolos-4.jpg' } },
            { id: 5, type: 'image', data: { filename: 'jolos-5.jpg' } },
            { id: 6, type: 'image', data: { filename: 'jolos-6.jpg' } },
            { id: 7, type: 'video', data: { filename: 'jolos.mp4' } },
        ],
    },
    {
        color: '#FFFFFF',
        backgroundColor: '#24BAA9',
        name: 'ooloom',
        title: { en: 'Ööloom', et: 'Ööloom' },
        description: {
            en: 'Home of the original face blankets.',
            et: 'Unemaskid ja muud Eestis disainitud unetooted.',
        },
        role: ['Web Design', 'Development', 'eCommerce'],
        url: 'https://ooloomstore.com/',
        awards: ['Won Silver Egg 2017', 'Won Silver Estonian Design Awards 2017'],
        content: [
            { id: 1, type: 'image', data: { filename: 'ooloom-device.png' } },
            { id: 2, type: 'image', data: { filename: 'ooloom-1.png' } },
            { id: 3, type: 'image', data: { filename: 'ooloom-2.png' } },
            { id: 4, type: 'image', data: { filename: 'ooloom-3.png' } },
            { id: 5, type: 'image', data: { filename: 'ooloom-4.png' } },
            { id: 6, type: 'image', data: { filename: 'ooloom-5.png' } },
        ],
    },
    // {
    //     color: '#4F51FF',
    //     backgroundColor: '#f0f0fe',
    //     name: 'taxscouts',
    //     title: { en: 'TaxScouts', et: 'TaxScouts' },
    //     description: { en: 'Your digital tax advisor.', et: '' },
    //     awards: [],
    //     url: 'https://taxscouts.com',
    //     role: ['Web Design', 'UI/UX'],
    //     content: [
    //         { id: 1, type: 'image', data: { filename: 'taxscouts-device.png' } },
    //         { id: 2, type: 'image', data: { filename: 'taxscouts-screens-01.png' } },
    //         { id: 3, type: 'image', data: { filename: 'taxscouts-screens-02.png' } },
    //         { id: 4, type: 'image', data: { filename: 'taxscouts-screens-03.png' } },
    //         { id: 5, type: 'image', data: { filename: 'taxscouts-illustration.png' } },
    //     ],
    // },
    {
        color: '#22304a',
        backgroundColor: '#00c6ff',
        name: 'transferwise',
        title: { en: '(Transfer)­Wise', et: '(Transfer)­Wise' },
        titleNoWrap: true,
        description: { en: 'Bye bye bank fees, hello world.', et: '' },
        role: ['UI/UX', 'Web Design', 'Graphic Design'],
        awards: [
            'Won Golden Egg 2015',
            'Won Gold Estonian Design Awards 2015',
            'Won Silver Estonian Design Awards 2015',
        ],
        content: [
            { id: 1, type: 'image', data: { filename: 'transferwise-1.jpg' } },
            { id: 2, type: 'image', data: { filename: 'transferwise-6.jpg' } },
            { id: 3, type: 'image', data: { filename: 'transferwise-book-1.jpg' } },
            { id: 4, type: 'image', data: { filename: 'transferwise-2.jpg' } },
            { id: 5, type: 'image', data: { filename: 'transferwise-3.png' } },
            { id: 6, type: 'image', data: { filename: 'transferwise-4.png' } },
            { id: 7, type: 'image', data: { filename: 'transferwise-shf-1.png' } },
        ],
    },
    {
        color: '#e41c24',
        backgroundColor: '#ffffff',
        name: 'toggl',
        title: { en: 'Toggl', et: 'Toggl' },
        description: { en: 'The time tracker.', et: '' },
        role: ['UI/UX', 'App design'],
        awards: ['Won Golden Egg 2014', 'Won Silver Estonian Design Awards 2014', 'Won Bronze Egg 2015'],
        content: [
            { id: 1, type: 'image', data: { filename: 'toggl-1.png' } },
            { id: 2, type: 'image', data: { filename: 'toggl-2.png' } },
            { id: 3, type: 'image', data: { filename: 'toggl-3.png' } },
            { id: 4, type: 'image', data: { filename: 'toggl-4.png' } },
            { id: 5, type: 'image', data: { filename: 'toggl-5.png' } },
            { id: 6, type: 'image', data: { filename: 'toggl-6.png' } },
        ],
    },
    {
        color: '#f0d559',
        backgroundColor: '#492366',
        name: 'tallinn-music-week',
        title: { en: 'Tallinn Music Week', et: 'Tallinn Music Week' },
        description: {
            en: 'Biggest indoor festival in the Baltic-Nordic region.',
            et: 'Uue muusika festival.',
        },
        role: ['Web design', 'Development'],
        awards: ['Won Golden Egg 2015'],
        content: [
            { id: 1, type: 'image', data: { filename: 'tallinn-music-week-1.jpg' } },
            { id: 2, type: 'image', data: { filename: 'tallinn-music-week-2.jpg' } },
            { id: 3, type: 'image', data: { filename: 'tallinn-music-week-3.jpg' } },
            { id: 4, type: 'image', data: { filename: 'tallinn-music-week-4.jpg' } },
            { id: 5, type: 'image', data: { filename: 'tallinn-music-week-5.jpg' } },
            { id: 6, type: 'image', data: { filename: 'tallinn-music-week-6.jpg' } },
            { id: 7, type: 'image', data: { filename: 'tallinn-music-week-7.jpg' } },
            { id: 8, type: 'image', data: { filename: 'tallinn-music-week-8.jpg' } },
            { id: 9, type: 'image', data: { filename: 'tallinn-music-week-9.jpg' } },
            { id: 10, type: 'image', data: { filename: 'tallinn-music-week-10.jpg' } },
            { id: 11, type: 'image', data: { filename: 'tallinn-music-week-11.jpg' } },
            { id: 12, type: 'image', data: { filename: 'tallinn-music-week-12.jpg' } },
        ],
    },
    {
        color: '#FFFFFF',
        backgroundColor: '#1318c8',
        name: 'helmes',
        title: { en: 'Helmes', et: 'Helmes' },
        description: {
            en: 'Business software alchemists.',
            et: 'Rahvusvaheliste ärikriitiliste tarkvaralahenduste arhitektid.',
        },
        role: ['Visual Identity', 'Web Design', 'Development', 'Illustration', 'Animation'],
        awards: ['Won Bronze Estonian Design Awards 2017'],
        content: [
            { id: 1, type: 'image', data: { filename: 'helmes-1.png' } },
            { id: 2, type: 'image', data: { filename: 'helmes-pages.jpg' } },
            { id: 3, type: 'image', data: { filename: 'helmes-icons.gif' } },
            { id: 4, type: 'image', data: { filename: 'helmes-illustrations.gif' } },
            { id: 5, type: 'image', data: { filename: 'helmes-logo.png' } },
            { id: 6, type: 'video', data: { filename: 'helmes-screens.mp4' } },
        ],
    },
    {
        color: '#6354f3',
        backgroundColor: '#f2f5f7',
        name: 'billdogg',
        title: { en: 'Billdogg', et: 'Billdogg' },
        description: { en: 'Beautifully simple invoicing.', et: 'Minimalistlik arveldustarkvara.' },
        role: ['Visual identity', 'Web design', 'UI/UX'],
        awards: ['Won Bronze Estonian Design Awards 2017'],
        url: 'https://billdogg.com',
        content: [
            { id: 1, type: 'image', data: { filename: 'billdogg-1.png' } },
            { id: 2, type: 'image', data: { filename: 'billdogg-2.png' } },
            { id: 3, type: 'image', data: { filename: 'billdogg-3.png' } },
            { id: 4, type: 'image', data: { filename: 'billdogg-4.png' } },
            { id: 5, type: 'image', data: { filename: 'billdogg-5.png' } },
            { id: 6, type: 'image', data: { filename: 'billdogg-6.png' } },
        ],
    },
    {
        color: '#FFFFFF',
        backgroundColor: '#003cff',
        name: 'e-estonia',
        title: { en: 'e-Estonia', et: 'e-Estonia' },
        description: { en: 'Story of the digital society.', et: 'Eesti digiühiskonna edulugu.' },
        collaboration: 'Created in collaboration with TANK and Callisto',
        role: ['Web design', 'Development'],
        awards: [],
        url: 'https://e-estonia.com',
        content: [
            { id: 1, type: 'image', data: { filename: 'e-estonia-1.png' } },
            { id: 2, type: 'image', data: { filename: 'e-estonia-2.png' } },
            { id: 3, type: 'image', data: { filename: 'e-estonia-3.png' } },
            { id: 4, type: 'image', data: { filename: 'e-estonia-4.png' } },
            { id: 5, type: 'image', data: { filename: 'e-estonia-6.png' } },
            { id: 6, type: 'image', data: { filename: 'e-estonia-5.png' } },
            { id: 7, type: 'image', data: { filename: 'e-estonia-7.png' } },
        ],
    },
    {
        color: '#6f95ed',
        backgroundColor: '#ffffff',
        name: 'rahvaulikool',
        title: { en: 'Rahva­ülikool', et: 'Rahva­ülikool' },
        titleNoWrap: true,
        description: { en: '', et: 'Elukestev õpe.' },
        role: ['Visual identity', 'Web design', 'Development'],
        awards: ['Won Golden Egg 2016', 'Won Bronze Estonian Design Awards 2015'],
        url: 'https://kultuur.ee',
        content: [
            { id: 1, type: 'image', data: { filename: 'rahvaulikool-device.png' } },
            { id: 2, type: 'image', data: { filename: 'rahvaulikool-1.png' } },
            { id: 3, type: 'image', data: { filename: 'rahvaulikool-2.png' } },
            { id: 4, type: 'image', data: { filename: 'rahvaulikool-icons.png' } },
            { id: 5, type: 'image', data: { filename: 'rahvaulikool-6.jpg' } },
            { id: 6, type: 'image', data: { filename: 'rahvaulikool-7.jpg' } },
            { id: 7, type: 'image', data: { filename: 'rahvaulikool-logo.png' } },
        ],
    },
]

export default work
