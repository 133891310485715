import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { PATH_MEDIA } from '../constants'
import { tablet } from '../styles'

const Container = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;
    margin-bottom: 24px;
    padding-top: ${({ ratio }) => (ratio ? `${ratio}%` : '56.25%')};
    ${tablet`
        margin-bottom: 50vh;
    `};
`

const Video = styled.video`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

class WorkContentVideo extends React.Component {
    render() {
        const { data } = this.props
        const { filename, ratio } = data
        return (
            <Container ratio={ratio}>
                <Video preload="auto" loop={true} controls="" autoPlay={true} muted={true} playsInline={true}>
                    <source src={PATH_MEDIA + '/' + filename} type="video/mp4" />
                </Video>
            </Container>
        )
    }
}

WorkContentVideo.propTypes = {
    data: PropTypes.object.isRequired,
    color: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
}

export default WorkContentVideo
