import _bindAll from 'lodash/bindAll'
import _find from 'lodash/find'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter, Route, Switch } from 'react-router-dom'
import Transition from 'react-transition-group/Transition'
import TransitionGroup from 'react-transition-group/TransitionGroup'

import styled from 'styled-components'

import { paths } from '../config'
import { LANGUAGE_EN } from '../constants'
import { languageFromPath, removeLanguageFromPath, hexToRgbA, metaTags } from '../helpers'
import { color, tablet } from '../styles'

import Contact from './Contact'
import Home from './Home'
import Sidebar from './Sidebar'
import SidebarToggle from './SidebarToggle'
import Start from './Start'
import Studio from './Studio'
import WorkContainer from './WorkContainer'
import Works from './Works'

const Container = styled.div`
    color: ${({ foregroundColor }) => foregroundColor};
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    background: ${({ backgroundColor }) => backgroundColor};
    transition: background 0.6s;
    a {
        color: ${({ foregroundColor }) => foregroundColor};
    }
`

const Content = styled.div`
    position: relative;
    z-index: 100;
    width: 100%;
    min-height: 100%;
`

const Background = styled.div`
    /* display: none; */
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    /* opacity: ${({ isHidden }) => (isHidden ? 0 : 0.15)}; */
    opacity: 0.15;
    transition: opacity 0.6s 0.3s;
    pointer-events: none;
    user-select: none;
    ${tablet`
        display: block;
    `};
`

const BackgroundImageContainer = styled.div`
    position: absolute;
    top: 35%;
    left: 60%;
    width: 90vw;
    height: 65vh;
    transform: translate(-50%, -50%);
    ${tablet`
        top: 55%;
        left: 60%;
        width: 50vw;
        height: 65vh;
        transform: translate(
            ${({ translateX, translateY }) =>
                translateX && translateY ? `${translateX}%, ${translateY}%` : '-50%, -50%'}
        );
    `};
`

const BackgroundImage = styled.img`
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
`

const clipReveal = `polygon(0 0, 100% 0, 100% 100%, 0 100%)`
const clipHide = `polygon(100% 0, 100% 0, 100% 100%, 100% 100%)`

const ImageTransition = styled.div`
    transition: all 1s 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    clip-path: ${clipHide};
    ${({ state }) =>
        state === 'entering' &&
        `
        clip-path: ${clipReveal};
    `};
    ${({ state }) =>
        state === 'entered' &&
        `
        clip-path: ${clipReveal};
    `};
    ${({ state }) =>
        state === 'exiting' &&
        `
        clip-path: ${clipHide};
    `};
    ${({ state }) =>
        state === 'exited' &&
        `
        clip-path: ${clipHide};
    `};
`

const Body = styled.div`
    transition: opacity 0.3s, transform 0.6s;
    ${({ isHidden }) =>
        isHidden &&
        `
            opacity: 0;
            pointer-events: none;
        `}
    ${tablet`
        opacity: 1;
        pointer-events: auto;
        transform: none;
    `};
`

const BodyFade = styled.div`
    display: block;
    position: fixed;
    bottom: 0;
    z-index: 2000;
    width: 100%;
    height: 192px;
    transition: background 0.6s;
    background: linear-gradient(to top, ${({ color }) => hexToRgbA(color, 1)}, ${({ color }) => hexToRgbA(color, 0)});
    pointer-events: none;
    ${tablet`
        display: none;
    `};
`

class Main extends React.Component {
    state = {
        backgroundImages: [],
        currentBackgroundId: null,
        isMobileNavOpen: false,
        language: LANGUAGE_EN,
        mouseX: null,
        mouseY: null,
        shouldDisplayBackground: null,
    }

    constructor() {
        super()
        _bindAll(this, ['toggleSidebar', 'handleMouseMove', 'handleMouseEnter', 'handleMouseLeave'])
    }

    componentDidMount() {
        setTimeout(() => this.setState({ shouldDisplayBackground: true }), 300)
    }

    componentDidUpdate(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
            this.setState({ isMobileNavOpen: false })
        }
    }

    static getDerivedStateFromProps(props) {
        const path = props.location.pathname

        let currentBackgroundId = null

        const backgroundImages = props.data.about.map((o, i) => {
            return { id: i, src: o.backgroundImage, path: o.path }
        })

        if (_find(props.data.about, { path: removeLanguageFromPath(path) })) {
            currentBackgroundId = _find(backgroundImages, { path: removeLanguageFromPath(path) }).id
        }

        return {
            language: languageFromPath(path),
            backgroundImages,
            currentBackgroundId,
        }
    }

    workForeground() {
        const activeWork = removeLanguageFromPath(this.props.location.pathname).split('/')[2] || null
        const work = _find(this.props.data.work, { name: activeWork })

        if (work) return work.color
        return color.neutralDarkest
    }

    foregroundColor() {
        const path = '/' + removeLanguageFromPath(this.props.location.pathname).split('/')[1]
        switch (path) {
            case paths.about:
                return color.neutral
            case paths.work:
                return this.workForeground(path)
            default:
                return color.neutralDarkest
        }
    }

    workBackground() {
        const activeWork = removeLanguageFromPath(this.props.location.pathname).split('/')[2] || null
        const work = _find(this.props.data.work, { name: activeWork })

        if (work) return work.backgroundColor
        return color.neutralLighter
    }

    backgroundColor() {
        const path = '/' + removeLanguageFromPath(this.props.location.pathname).split('/')[1]
        switch (path) {
            case paths.about:
                return color.neutralDarkest
            case paths.work:
                return this.workBackground(path)
            default:
                return color.neutralLighter
        }
    }

    handleMouseEnter(e) {
        this.setState({
            shouldDisplayBackground: true,
        })
    }

    handleMouseLeave(e) {
        this.setState({
            shouldDisplayBackground: false,
        })
    }

    handleMouseMove(e) {
        this.setState({
            mouseX: e.clientX,
            mouseY: e.clientY,
        })
    }

    shouldKeepSidebarVisible() {
        const path = '/' + removeLanguageFromPath(this.props.location.pathname).split('/')[1]
        switch (path) {
            case paths.root:
                return true
            default:
                return false
        }
    }

    toggleSidebar() {
        this.setState({ isMobileNavOpen: !this.state.isMobileNavOpen })
    }

    offsetX() {
        const { mouseX } = this.state
        const fromLeft = Math.round((mouseX / window.innerWidth) * 100)
        return 50 - fromLeft
    }

    offsetY() {
        const { mouseY } = this.state
        const fromTop = Math.round((mouseY / window.innerHeight) * 100)
        return 50 - fromTop
    }

    render() {
        const { language, shouldDisplayBackground, isMobileNavOpen, backgroundImage } = this.state
        const { backgroundImages, currentBackgroundId } = this.state

        const foregroundColor = this.foregroundColor()
        const backgroundColor = this.backgroundColor()

        const translateX = -50 + this.offsetX() / 50
        const translateY = -50 + this.offsetY() / 70

        return (
            <Container
                backgroundColor={backgroundColor}
                foregroundColor={foregroundColor}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onMouseMove={this.handleMouseMove}
            >
                <Helmet>
                    {metaTags({
                        language,
                        title: 'Haiku — a digital-first design studio',
                        description:
                            'Haiku is a digital-first design studio based in Tallinn, Estonia. We build digital experiences for web and mobile, design brand identities and provide creative services from illustration to motion graphics.',
                    })}
                </Helmet>
                <Content>
                    <SidebarToggle
                        backgroundColor={foregroundColor}
                        foregroundColor={backgroundColor}
                        isMobileNavOpen={isMobileNavOpen}
                        toggleHandler={this.toggleSidebar}
                    />
                    <Sidebar
                        {...this.props}
                        backgroundColor={backgroundColor}
                        foregroundColor={foregroundColor}
                        isHidden={!isMobileNavOpen}
                        keepVisible={this.shouldKeepSidebarVisible()}
                        language={language}
                    />
                    <Body isHidden={isMobileNavOpen}>
                        <Switch>
                            <Route
                                path={'/:lang?' + paths.about + '/:slideName?'}
                                render={() => <Studio {...this.props} language={language} />}
                            />
                            <Route
                                path={'/:lang?' + paths.work}
                                exact
                                render={() => <Works {...this.props} language={language} />}
                            />
                            <Route
                                path={'/:lang?' + paths.work + '/:workName?'}
                                render={() => <WorkContainer {...this.props} language={language} />}
                            />

                            <Route
                                path={'/:lang?' + paths.start}
                                render={() => <Start {...this.props} language={language} />}
                            />
                            <Route
                                path={'/:lang?' + paths.contact}
                                render={() => <Contact {...this.props} language={language} />}
                            />
                            <Route
                                path={'/:lang?' + paths.root}
                                render={() => <Home {...this.props} language={language} />}
                            />
                        </Switch>
                        <BodyFade color={backgroundColor} />
                    </Body>
                </Content>
                <Background isHidden={!shouldDisplayBackground || !backgroundImage}>
                    <BackgroundImageContainer translateX={translateX} translateY={translateY}>
                        <TransitionGroup>
                            {backgroundImages
                                .filter((e) => e.id === currentBackgroundId)
                                .map(({ id, src }) => (
                                    <Transition key={id} timeout={1300}>
                                        {(state) => (
                                            <ImageTransition state={state}>
                                                {src && <BackgroundImage src={src} alt="" />}
                                            </ImageTransition>
                                        )}
                                    </Transition>
                                ))}
                        </TransitionGroup>
                    </BackgroundImageContainer>
                </Background>
            </Container>
        )
    }
}

Main.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
}

export default withRouter(Main)
