import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { cols, font, tablet, desktop, desktopLarge } from '../styles'

import AnimateText from './AnimateText'
import { Slide } from './Studio'

const Heading = styled.h1`
    font-family: ${font.heading};
    font-size: ${font.large};
    line-height: 1.2;
    margin-bottom: 36px;
    ${tablet`
        font-size: ${font.largest};
        line-height: 1.1;
        position: absolute;
        top: 9vh;
        left: 0;
        width: ${cols(6)};
        margin-bottom: 0;
    `};
    ${desktop`
        font-size: ${font.huge};
        line-height: 1;
    `};
`

const Body = styled.div`
    ${tablet`
        position: absolute;
        left: 0;
        top: 50vh;
        width: ${cols(6)};
    `};
`

const Columns = styled.div`
    ${desktop`
        display: flex;
        justify-content: space-between;
    `};
`

const Column = styled.div`
    &:not(:last-child) {
        margin-bottom: 48px;
    }
    ${tablet`
        flex: 0 0 ${cols(2)};
        margin-bottom: 24px;
        padding-right: 4vw;
    `};
`

const ColumnHeading = styled.h2`
    font-family: ${font.heading};
    font-size: ${font.medium};
    line-height: 1.4;
    ${desktopLarge`
        font-size: ${font.large};
        line-height: 1.3;
    `};
`

const ColumnBody = styled.div`
    font-size: ${font.normal};
    line-height: 1.5;
    margin-top: 24px;
    ${tablet`
        font-size: ${font.normal};
        line-height: 1.5;
        padding-right: 4vw;
    `};
    ${desktopLarge`
        font-size: ${font.medium};
        line-height: 1.4;
    `};
`

const ColumnList = styled.div`
    font-size: ${font.normal};
    line-height: 1.5;
    margin-top: 24px;
    ${tablet`
        font-size: ${font.normal};
        line-height: 1.5;
        margin-top: 36px;
    `};
    ${desktopLarge`
        font-size: ${font.medium};
        line-height: 1.4;
    `};
`

const ColumnListItem = styled.div``

class StudioServices extends React.Component {
    strings = {
        headingFirst: { en: 'What do', et: 'Mida me' },
        headingSecond: { en: 'we do.', et: 'teeme.' },
        columns: [
            {
                titleFirst: { en: 'Web design', et: 'Veebidisain' },
                titleSecond: { en: 'and strategy', et: 'ja strateegia' },
                body: {
                    en: 'Support from ideation to execution and beyond',
                    et: 'Planeerime ja teostame terviklahendusi',
                },
                services: [
                    { en: 'Design & development', et: 'Veebiarendus' },
                    { en: 'WordPress', et: 'WordPress' },
                    { en: 'React & Next.js', et: 'React & Next.js' },
                ],
            },
            {
                titleFirst: { en: 'Digital product', et: 'Digitaalne' },
                titleSecond: { en: 'design', et: 'tootedisain' },
                body: {
                    en: 'Act as partners to startups and in-house teams',
                    et: 'Oleme partneriks idufirmadele ja tootemeeskondadele',
                },
                services: [
                    { en: 'Interaction design', et: 'Veebirakendused' },
                    { en: 'User interface design', et: 'Kasutajaliidesed' },
                    { en: 'Experience design', et: 'Kasutajakogemus' },
                ],
            },
            {
                titleFirst: { en: 'Branding and', et: 'Bränding ja' },
                titleSecond: { en: 'graphic design', et: 'graafiline disain' },
                body: {
                    en: 'Bring clarity to organisations visual communications',
                    et: 'Aitame luua ja edendada sinu organisatsiooni nägu',
                },
                services: [
                    { en: 'Graphic and print design', et: 'Graafiline disain' },
                    { en: 'Branding and visual identities', et: 'Visuaalne identiteet' },
                    { en: 'Illustration and animation', et: 'Illustratsioon ja animatsioon' },
                ],
            },
        ],
    }

    render() {
        const { language } = this.props

        let s = 1
        const stagger = () => s++

        return (
            <Slide>
                <Heading>
                    <AnimateText extraSpacing text={this.strings.headingFirst[language]} stagger={stagger()} />
                    <AnimateText extraSpacing text={this.strings.headingSecond[language]} stagger={stagger()} />
                </Heading>
                <Body>
                    <Columns>
                        {this.strings.columns.map((column, i) => (
                            <Column key={i}>
                                <ColumnHeading>
                                    <AnimateText text={column.titleFirst[language]} stagger={stagger()} />
                                    <AnimateText text={column.titleSecond[language]} stagger={stagger()} />
                                </ColumnHeading>
                                <ColumnBody>
                                    <AnimateText text={column.body[language]} stagger={stagger()} />
                                </ColumnBody>
                                <ColumnList>
                                    {column.services.map((service, j) => (
                                        <ColumnListItem key={j}>
                                            <AnimateText text={service[language]} stagger={stagger()} />
                                        </ColumnListItem>
                                    ))}
                                </ColumnList>
                            </Column>
                        ))}
                    </Columns>
                </Body>
            </Slide>
        )
    }
}

StudioServices.propTypes = {
    language: PropTypes.string.isRequired,
}

export default StudioServices
