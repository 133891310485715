import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { cols, font, tablet, desktop, desktopLarge } from '../styles'

import AnimateText from './AnimateText'
import { Slide } from './Studio'

const Heading = styled.h1`
    font-family: ${font.heading};
    font-size: ${font.large};
    line-height: 1.2;
    margin-bottom: 36px;
    ${tablet`
        font-size: ${font.largest};
        line-height: 1.1;
        position: absolute;
        top: 9vh;
        left: 0;
        width: ${cols(6)};
        margin-bottom: 0;
    `};
    ${desktop`
        font-size: ${font.huge};
        line-height: 1;
    `};
`

const Body = styled.div`
    ${tablet`
        position: absolute;
        left: 0;
        top: 50vh;
        width: ${cols(6)};
    `};
`

const Columns = styled.div`
    ${desktop`
        display: flex;
        justify-content: space-between;
    `};
`
const Column = styled.div`
    ${desktop`
        flex: 0 0 28%;
    `};
`

// const ColumnHeading = styled.h2`
//     font-family: ${font.heading};
//     ${tablet`
//         font-size: ${font.medium};
//         line-height: 1.5;
//     `};
//     ${desktop`
//         // font-size: ${font.large};
//         line-height: 1.3;
//     `};
// `

// const ColumnBody = styled.div`
//     ${tablet`
//         font-size: ${font.normal};
//         line-height: 1.5;
//         margin-top: 24px;
//     `};
// `

const ColumnList = styled.div`
    font-size: ${font.normal};
    line-height: 1.5;
    ${tablet`
        font-size: ${font.normal};
        line-height: 1.5;
    `};
    ${desktopLarge`
        font-size: ${font.medium};
        line-height: 1.4;
    `};
`

const ColumnListItem = styled.div`
    &:not(:last-child) {
        /* margin-bottom: 9px; */
    }
`

class StudioClients extends React.Component {
    strings = {
        headingFirst: { en: '30+ awards,', et: 'Meie' },
        headingSecond: { en: '100+ clients.', et: 'kliendid.' },
    }

    render() {
        const { language } = this.props

        let s = 1
        const stagger = () => s++

        return (
            <Slide>
                <Heading>
                    <AnimateText extraSpacing text={this.strings.headingFirst[language]} stagger={stagger()} />
                    <AnimateText extraSpacing text={this.strings.headingSecond[language]} stagger={stagger()} />
                </Heading>
                <Body>
                    <Columns>
                        <Column>
                            <ColumnList>
                                <ColumnListItem>
                                    <AnimateText text="Ajujaht" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Arvo Pärt Center" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Billdogg" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Change" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Craftory" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Dambis" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="e-Estonia Showroom" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Eesti Gaas" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Enterprise Estonia" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Estonian Maritime Museum" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Evocon" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Helmes" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="HITSA" stagger={stagger()} />
                                </ColumnListItem>
                            </ColumnList>
                        </Column>
                        <Column>
                            <ColumnList>
                                <ColumnListItem>
                                    <AnimateText text="HeyDay Organic" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Icefire" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Jolos" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="JLP Kommunikatsioonibüroo" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Latitude59" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="LeapIN (now Xolo)" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Lingvist" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="MarketInvoice" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Müürileht" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="National Archives of Estonia" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Noblessner" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Portail" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Toggl" stagger={stagger()} />
                                </ColumnListItem>
                            </ColumnList>
                        </Column>
                        <Column>
                            <ColumnList>
                                <ColumnListItem>
                                    <AnimateText text="Tallinn Music Week" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Tallinna Rahvaülikool" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="TaxScouts" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Teamweek" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Testlio" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Toggl" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Tolm" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="(Transfer)Wise" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Trip.ee" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Tutor.id" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Veriff" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="Ööloom" stagger={stagger()} />
                                </ColumnListItem>
                                <ColumnListItem>
                                    <AnimateText text="and others" stagger={stagger()} />
                                </ColumnListItem>
                            </ColumnList>
                        </Column>
                    </Columns>
                </Body>
            </Slide>
        )
    }
}

StudioClients.propTypes = {
    language: PropTypes.string.isRequired,
}

export default StudioClients
