import _bindAll from 'lodash/bindAll'
import _get from 'lodash/get'
import _find from 'lodash/find'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { paths } from '../config'
import { KEY_LEFT_ARROW, KEY_RIGHT_ARROW } from '../constants'
import { hexToRgbA, languagePath, title, metaTags } from '../helpers'
import { color, font, cols, tablet, desktop, colors } from '../styles'

import Pointer from './Pointer'
import Next from './Next'

const Container = styled.div`
    ${tablet`
        width: ${cols(6)};
        min-height: 100vh;
        margin-left: ${cols(2, 72)};
    `};
`

export const SlideContainer = styled.div`
    position: relative;
    z-index: 1;
    ${tablet`
        height: 100vh;
    `};
`

export const Slide = styled.div`
    padding: 9vh 24px 18vh;
    ${tablet`
        padding: 0;
    `};
`

export const Heading = styled.h1`
    font-family: ${font.heading};
    ${tablet`
        font-size: ${font.large};
        line-height: 1.3;
        margin-bottom: 9vh;
    `};
    ${desktop`
        font-size: ${font.larger};
    `};
`

export const Body = styled.div`
    font-size: ${font.medium};
    font-weight: ${font.weight.medium};
    line-height: 1.5;
`

const Progress = styled.div`
    position: fixed;
    z-index: 1100;
    right: 24px;
    top: 0;
    width: calc(100% - 48px);
    height: 2px;
    background: ${({ color }) => (color ? hexToRgbA(color, 0.2) : 'none')};
    ${tablet`
        width: ${cols(6)};
        right: 72px;
        height: 4px;
    `};
`

const Indicator = styled.div`
    position: absolute;
    top: 0;
    left: ${({ total, position }) => (100 / total) * position + '%'};
    width: ${({ total }) => 100 / total + '%'};
    height: 100%;
    background: ${({ color }) => color};
    transition: all 0.3s;
    transition-timing-function: ease-in-out;
`

// const Nav = styled.div`
//     position: absolute;
//     overflow: hidden;
//     top: 100%;
//     left: 0;
//     width: 100%;
//     ${tablet`
//         display: flex;
//     `};
// `

// const NavLink = styled(Link)`
//     color: ${color.neutral};
//     font-family: ${font.heading};
//     font-size: ${font.tiny};
//     font-weight: ${font.weight.medium};
//     text-align: center;
//     text-decoration: none;
//     display: block;
//     padding: 15px;
//     opacity: 0;
//     transform: translateY(-100%);
//     transition: all 0.3s;
// `

// const NavItem = styled.div`
//     &:hover {
//         ${NavLink} {
//             transform: translateY(0);
//             opacity: ${({ isActive }) => (isActive ? 1 : 0.3)};
//         }
//     }
//     ${tablet`
//         flex: 0 0 ${({ width }) => width};
//     `};
// `

class Studio extends React.Component {
    state = {
        currentSlide: null,
        shouldRedirectTo: null,
        shouldDisplayPointer: null,
        mouseX: null,
        mouseY: null,
        mouseOnRightSide: null,
    }

    constructor() {
        super()
        _bindAll(this, [
            'handleKeyPress',
            'handleMouseMove',
            'handleMouseEnter',
            'handleMouseLeave',
            'next',
            'prev',
            'handleNav',
        ])
    }

    static getDerivedStateFromProps(nextProps) {
        const slides = nextProps.data.about
        const { slideName } = nextProps.match.params
        const currentSlide = _find(slides, { name: slideName })
        const shouldRedirectTo = !currentSlide
            ? languagePath(nextProps.language, paths.about + '/' + slides[0].name)
            : false

        return {
            currentSlide,
            shouldRedirectTo,
        }
    }

    componentDidMount() {
        this.tryRedirect()
        document.addEventListener('keydown', this.handleKeyPress)
    }

    componentDidUpdate() {
        this.tryRedirect()
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress)
    }

    tryRedirect() {
        if (this.state.shouldRedirectTo) {
            this.props.history.replace(this.state.shouldRedirectTo)
        }
    }

    handleKeyPress(e) {
        switch (e.keyCode) {
            case KEY_RIGHT_ARROW:
                this.next()
                break
            case KEY_LEFT_ARROW:
                this.prev()
                break
            default:
                return
        }
    }

    handleMouseMove(e) {
        this.setState({
            mouseX: e.clientX,
            mouseY: e.clientY,
            mouseOnRightSide: e.clientX > window.innerWidth / 2.2,
        })
    }

    handleMouseEnter(e) {
        this.setState({
            shouldDisplayPointer: true,
        })
    }

    handleMouseLeave(e) {
        this.setState({
            shouldDisplayPointer: false,
        })
    }

    next() {
        const slides = this.props.data.about
        const index = slides.indexOf(this.state.currentSlide)
        const newIndex = slides.length - 1 > index ? index + 1 : 0
        this.goTo(slides[newIndex].name)
    }

    prev() {
        const slides = this.props.data.about
        const index = slides.indexOf(this.state.currentSlide)
        const newIndex = index > 0 ? index - 1 : slides.length - 1
        this.goTo(slides[newIndex].name)
    }

    goTo(slideName) {
        this.props.history.push(languagePath(this.props.language, paths.about + '/' + slideName))
    }

    handleNav() {
        if (this.state.mouseOnRightSide) {
            this.next()
        } else {
            this.prev()
        }
    }

    render() {
        const { data, language } = this.props
        const { currentSlide, shouldDisplayPointer } = this.state
        const slides = _get(data, ['about'], [])
        const numberOfSlides = slides.length
        const currentSlideNumber = slides.indexOf(currentSlide)

        return (
            <Container
                onMouseMove={this.handleMouseMove}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                {currentSlide && (
                    <React.Fragment>
                        <Helmet>
                            {metaTags({
                                language,
                                title: title(currentSlide.title),
                                themeColor: colors.neutralDarkest,
                            })}
                        </Helmet>
                        <SlideContainer>
                            <currentSlide.component language={language} />
                        </SlideContainer>
                        <Progress color={color.neutral}>
                            <Indicator position={currentSlideNumber} total={numberOfSlides} color={color.neutral} />
                            {/* <Nav>
                                {slides.map((slide) => (
                                    <NavItem
                                        key={slide.name}
                                        width={100 / numberOfSlides + '%'}
                                        isActive={slide.name === currentSlide.name}
                                    >
                                        <NavLink to={paths.about + '/' + slide.name}>{slide.title}</NavLink>
                                    </NavItem>
                                ))}
                            </Nav> */}
                        </Progress>
                        {shouldDisplayPointer && (
                            <Pointer
                                x={this.state.mouseX}
                                y={this.state.mouseY}
                                isReversed={!this.state.mouseOnRightSide}
                                handleClick={this.handleNav}
                            />
                        )}
                        <Next color={colors.neutralLighter} handleClick={this.next} />
                        <div />
                    </React.Fragment>
                )}
            </Container>
        )
    }
}

Studio.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
}

export default withRouter(Studio)
