import _isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
        if (!_isEqual(this.props.location, prevProps.location)) {
            window.scrollTo(0, 0)
            window.ga('send', 'pageview', { page: window.location.pathname + window.location.search })
            // setTimeout(() => {
            //     window.scrollTo(0, 0)
            // }, 1)
        }
    }
    render() {
        return this.props.children
    }
}

ScrollToTop.propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
}

export default withRouter(ScrollToTop)
