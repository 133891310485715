import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

import { font, tablet, desktop } from '../styles'

import AnimateText from './AnimateText'
import { title } from '../helpers'

const Container = styled.div`
    overflow-x: hidden;
    ${tablet`
        margin-left: calc((100vw - 144px) / 8 * 2 + 72px);
        width: calc((100vw - 144px) / 8 * 6);
    `};
`

const Content = styled.div`
    padding: 12vh 0;
`

const Heading = styled.div`
    font-family: ${font.heading};
    font-size: ${font.medium};
    font-weight: ${font.weight.medium};
    line-height: 1.5;
    &:not(:last-child) {
        margin-bottom: 9vh;
    }
    ${tablet`
        font-size: ${font.large};
        line-height: 1.3;
    `};
    ${desktop`
        font-size: ${font.larger};
    `};
`

const Lead = styled.div`
    font-size: ${font.medium};
    line-height: 1.5;
    &:not(:last-child) {
        margin-bottom: 3vh;
    }
    p:not(:last-child) {
        margin-bottom: 2vh;
    }
`

const Conclusion = styled.div`
    font-size: ${font.medium};
    line-height: 1.5;
    &:not(:first-child) {
        margin-top: 6vh;
    }
    p:not(:last-child) {
        margin-bottom: 2vh;
    }
`

const Introduction = styled.div`
    margin-bottom: 12vh;
`

const H2 = styled.h2`
    font-family: ${font.heading};
    font-size: ${font.medium};
    font-weight: ${font.weight.medium};
    line-height: 1.5;
    &:not(:first-child) {
        margin-top: 9vh;
    }
    &:not(:last-child) {
        margin-bottom: 4vh;
    }
    ${tablet`
        font-size: ${font.large};
        line-height: 1.3;
    `};
    ${desktop`
        font-size: ${font.larger};
    `};
`

const H3 = styled.div`
    font-size: ${font.medium};
    line-height: 1.5;
    &:not(:last-child) {
        margin-bottom: 3vh;
    }
    p:not(:last-child) {
        margin-bottom: 2vh;
    }
`

const Body = styled.div`
    font-size: ${font.normal};
    line-height: 1.5;
    p:not(:last-child) {
        margin-bottom: 1vh;
    }
`
const Columns = styled.div`
    &:not(:first-child) {
        margin-top: 4vh;
    }
    &:not(:last-child) {
        margin-bottom: 4vh;
    }
    ${tablet`
        display: flex;
        justify-content: space-between;
    `};
`

const Column = styled.div`
    &:not(:first-child) {
        margin-top: 4vh;
        ${tablet`
            margin-top: 0;
        `};
    }
    ${tablet`
        flex: 0 0 47%;
    `};
`

const propTypes = {
    language: PropTypes.string.isRequired,
}

class Start extends React.Component {
    render() {
        let s = 1
        const stagger = () => s++
        return (
            <Container>
                <Helmet>
                    <title>{title('Start a project')}</title>
                </Helmet>
                <Content>
                    <Introduction>
                        <Heading>
                            <AnimateText text="Good outcome is the result" stagger={stagger()} />
                            <AnimateText text="of smart planning and clear communication." stagger={stagger()} />
                        </Heading>
                        <Lead>
                            <p>
                                We’ve put together this overview to give you a quick introduction how we approach new
                                projects and answer some frequently asked questions.
                            </p>
                            <p>
                                It covers the process of designing and building a website. But it also should give you a
                                broader insight into our thinking in general.
                            </p>
                            <p>Of course every project is unique and we always adapt accordingly.</p>
                        </Lead>
                    </Introduction>

                    <H2>Praparation</H2>
                    <Lead>Even before you contact us, think through the core problem that needs to be solved.</Lead>
                    <Body>
                        <p>What are the goals and how we can measure success?</p>
                        <p>
                            Who will need to be present from your organisation for the project to succeed (or even get
                            approved)?
                        </p>
                        <p>Will everyone have the time to commit to the project?</p>
                        <p>Is there a specific deadline or prefered timeline?</p>
                        <p>Why have you chosen us?</p>
                        <p>Do you have the budget for it? Read on.</p>
                    </Body>

                    <H2>Getting started</H2>

                    <H3>Request for proposal</H3>
                    <Body>
                        <p>
                            After receiving the initial brief or work description from you we typically collect some
                            more information about your organisation and your specific needs.
                        </p>
                        <p>
                            This may include an email, a call or/and an initial meeting. This is also the period where
                            we help you to figure out the more technical bits if needed.
                        </p>
                        <p>
                            Once we’ve determined the approximate scope and cost of the project we’ll send you the quote
                            and rough schedule.
                        </p>
                        <p>
                            In cases where there are some unknown aspects we might first provide a rough estimation that
                            we’ll fine tune once we have all the anwsers. This helps to save time early on if the budget
                            becomes an issue.
                        </p>
                    </Body>

                    <Columns>
                        <Column>
                            <H3>Contract</H3>
                            <Body>
                                <p>
                                    Once you as a client decide to go forward with the project, we set up a contract
                                    outlining the work to be done, timeline, cost and payment schedule. Typically the
                                    cost is split into two payments (at the beginning and on completion). We keep our
                                    contracts on point and short.
                                </p>
                            </Body>
                        </Column>
                        <Column>
                            <H3>Kick-off</H3>
                            <Body>
                                <p>Okay, we are ready to roll. This is the official start of the project.</p>
                                <p>
                                    Generally this is a meeting where everyone involved are present. In some cases this
                                    can also be an internal team meeting if the client is not able to physically attend.
                                    Then the necessary communication will be done before and after the internal meeting.
                                </p>
                                <p>In this meeting we go over the details of the project:</p>
                                <p>
                                    Goals and challenges Schedule and milestones Team, roles, responsibilities Content
                                    creation, 3rd party collaborators Everything else…
                                </p>
                                <p>
                                    After the kick-off meeting a Creative Brief or project specification is finalised.
                                    This is a document containing all the necessary information for the project team for
                                    executing the project.
                                </p>
                            </Body>
                        </Column>
                    </Columns>

                    <H2>Cost and time</H2>

                    <Columns>
                        <Column>
                            <H3>Websites</H3>
                            <Body>
                                <p>
                                    The correct answer is “it depends”. But this is not what you wanted to hear, right.
                                </p>
                                <p>
                                    A rough cost for a small to medium size website could be anything between
                                    €5000–20,000.
                                </p>
                                <p>
                                    This includes responsive bespoke design, integration with content management system,
                                    development of specific functionality, content migration plus guarantee and
                                    maintenance period.
                                </p>
                            </Body>
                        </Column>
                        <Column>
                            <H3>Visual identities</H3>
                            <Body>
                                <p>
                                    Cost for visual identities varies depending on the type of the organisation and
                                    desired outcome.
                                </p>
                                <p>
                                    Minimal identity including logo, colour palette and typographic treatment with usage
                                    guidelines typically costs €3000–5000.
                                </p>
                                <p>
                                    Extensive brandbook consisting of tens of different application scenarios and
                                    variations can cost considerably more.
                                </p>
                                <p>Okay, we are ready to roll. This is the official start of the project.</p>
                            </Body>
                        </Column>
                    </Columns>

                    <Columns>
                        <Column>
                            <H3>Hourly rate</H3>
                            <Body>
                                <p>
                                    Not all hours are created equal. And therefore it can be a dishonest way to approach
                                    a project.
                                </p>
                                <p>Whenever possible we estimate the work beforehand.</p>
                                <p>But there are few cases where billing per hour makes sense.</p>
                                <p>
                                    This is when project has certain uncertainties, eg changing spec, iterative or
                                    exploratory design work with in-house team, etc.
                                </p>
                                <p>Our hourly rate runs from €50-100, depending on the services.</p>
                            </Body>
                        </Column>
                        <Column>
                            <H3>Content</H3>
                            <Body>
                                <p>
                                    Content is the most important part of the website. And should be planned and
                                    budgeted as such from the beginning.
                                </p>
                                <p>Do we need a copywriter and/or translator? (Answer is yes.)</p>
                                <p>Do we need a photographer (or license photos)?</p>
                                <p>Do we need an illustrator?</p>
                                <p>Do we need an animator?</p>
                                <p>
                                    Depending on the project, answering yes to all or some of the questions may increase
                                    the total cost of the project significantly.
                                </p>
                            </Body>
                        </Column>
                    </Columns>

                    <Columns>
                        <Column>
                            <H3>How long does it take</H3>
                            <Body>
                                <p>Small to medium size websites usually take 6-12 weeks to complete.</p>
                                <p>Consider similar timeline for visual identites.</p>
                            </Body>
                        </Column>
                        <Column>
                            <H3>Availability</H3>
                            <Body>
                                <p>
                                    Important factor to consider while assessing project timelines is when we or equally
                                    you can take the project on.
                                </p>
                            </Body>
                        </Column>
                    </Columns>

                    <Conclusion>
                        This is just a rough overview what to expect budget and time wise. In the end every project is
                        different and will treated separately.
                    </Conclusion>

                    <H2>Ready to get going? Send us a brief overview hello@haiku.ee</H2>
                    <Lead>Or click here to ask us a quick question.</Lead>

                    <Columns>
                        <Column>
                            <H3>Learn more</H3>
                            <Body>
                                <p>Design process</p>
                                <p>SEO</p>
                                <p>Production</p>
                                <p>Hosting</p>
                                <p>Security</p>
                                <p>Going live</p>
                            </Body>
                        </Column>
                        <Column>
                            <H3>Pro tip</H3>
                            <Body>
                                <p>
                                    Our pricing takes into account how smoothly we see the project going. Therefore if
                                    client is prepared and commited to making things run smooth it can affect the final
                                    cost.
                                </p>
                                <p>
                                    Conversly if the project seems prone to delays or misunderstanding it will be
                                    calculated into the cost. Or most likely rejected to begin with.
                                </p>
                            </Body>
                        </Column>
                    </Columns>
                </Content>
            </Container>
        )
    }
}

Start.propTypes = propTypes

export default Start
