export const paths = {
    root: '/',
    about: '/about',
    studio: '/about/studio',
    services: '/about/services',
    clients: '/about/clients',
    people: '/about/people',
    getInTouch: '/about/get-in-touch',
    work: '/work',
    start: '/start',
    contact: '/hello',
}
