import PropTypes from 'prop-types'
import React from 'react'
import ImagesLoaded from 'react-images-loaded'
import styled from 'styled-components'

import { PATH_MEDIA } from '../constants'
import { hexToRgbA } from '../helpers'
import { tablet } from '../styles'

const Container = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 24px;
    opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
    transition: opacity 0.6s 0.6s;
    ${tablet`
        margin-bottom: 50vh;
    `};
`

const Image = styled.img`
    width: 100%;
    display: block;
    position: relative;
`

const Cover = styled.div`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ background }) => background};
    transform: translateX(${({ isLoaded }) => (isLoaded ? '100%' : 0)});
    transition: transform 1.2s 0.6s;
`

const Placeholder = styled.div`
    /* outline: 2px solid ${(props) => (props.isLoaded ? props.background : hexToRgbA(props.color, 0.1))}; */
    /* background: ${(props) => (props.isLoaded ? props.background : hexToRgbA(props.color, 0.05))}; */
    /* background: ${(props) => hexToRgbA(props.color, 0.05)}; */
    /* opacity: ${(props) => (props.isLoaded ? 1 : 0.05)}; */
    /* background-image: url('http://static.tumblr.com/3zmswwt/gL1ny5cm4/2cwrjc00.gif'); */
    transition: all 0.6s 0.6s;
    ${tablet`
        min-height: 300px;
    `};
`

class WorkContentImage extends React.Component {
    state = {
        imagesLoaded: false,
    }

    // componentDidMount() {
    //     console.log('componentDidMount')
    // }

    // componentDidUpdate() {
    //     console.log('componentDidUpdate')
    // }

    handleOnAlways = (instance) => {
        // console.log('all images loaded')
    }

    handleOnProgress = (instance, image) => {
        // var result = image.isLoaded ? 'loaded' : 'broken'
        // console.log('image is ' + result + ' for ' + image.img.src)
    }

    handleOnFail = (instance) => {
        // console.log('all images loaded, at least one is broken')
    }

    handleDone = (instance) => {
        // console.log('all images successfully loaded')
        this.setState({ imagesLoaded: true })
    }

    render() {
        const { data } = this.props
        return (
            <Placeholder isLoaded={this.state.imagesLoaded} color={this.props.color} background={this.props.background}>
                <Container isLoaded={this.state.imagesLoaded}>
                    <ImagesLoaded
                        // elementType={'ul'} // defaults to 'div'
                        // className={'your-container-class'} // defaults to 'images-loaded-container'
                        onAlways={this.handleOnAlways}
                        onProgress={this.handleOnProgress}
                        onFail={this.handleOnFail}
                        done={this.handleDone}
                        // background=".image" // true or child selector
                    >
                        <Cover isLoaded={this.state.imagesLoaded} background={this.props.background} />
                        <Image src={PATH_MEDIA + '/' + data.filename} alt="" />
                    </ImagesLoaded>
                </Container>
            </Placeholder>
        )
    }
}

WorkContentImage.propTypes = {
    data: PropTypes.object.isRequired,
    color: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
}

export default WorkContentImage
