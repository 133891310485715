import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

import { cols, font, desktop, tablet, desktopLarge } from '../styles'

import AnimateText from './AnimateText'
import { title } from '../helpers'

const Container = styled.div`
    padding: 24px;
    padding-top: 9vh;
    ${tablet`
        position: fixed;
        top: 0;
        right: 72px;
        width: ${cols(6)};
        height: 100vh;
        padding: 0;
    `};
`

const Content = styled.div`
    padding: 0;
`

const Heading = styled.h1`
    font-family: ${font.heading};
    font-size: ${font.large};
    line-height: 1.2;
    position: relative;
    z-index: 1;
    margin-bottom: 24px;
    ${tablet`
        font-size: ${font.largest};
        line-height: 1.1;
        top: -12px;
        margin-top: 50vh;
        margin-bottom: 0;
    `};
    ${desktop`
        font-size: ${font.huge};
        line-height: 1;
        top: -18px;
    `};
`

const Body = styled.div`
    font-size: ${font.normal};
    line-height: 1.5;
    width: 80%;
    ${tablet`
        font-size: ${font.normal};
        line-height: 1.5;
        position: absolute;
        left: 0;
        bottom: 9vh;
        width: ${cols(6)};
        margin-bottom: -5px;
    `};
    ${desktop`
        font-size: ${font.medium};
        width: ${cols(5)};
    `};
    ${desktopLarge`
        font-size: ${font.medium};
        width: ${cols(4)};
    `};
`

class Home extends React.Component {
    strings = {
        title: {
            en: 'Haiku – a digital-first design studio based in Tallinn, Estonia',
            et: 'Haiku – disainistuudio',
        },
        headingFirst: { en: 'Haiku is a', et: 'Haiku.' },
        headingSecond: { en: 'creative space.', et: 'Disainistuudio.' },
        body: {
            en: 'Digital- and graphic design, technology and branding.',
            et: 'Digitaalne- ja graafiline disain, tehnoloogia ning bränding.',
        },
    }

    render() {
        const { language } = this.props

        let s = 1
        const stagger = () => s++

        return (
            <Container>
                <Helmet>
                    <title>{title(this.strings.title[language], true)}</title>
                </Helmet>
                <Content>
                    <Heading>
                        <AnimateText extraSpacing text={this.strings.headingFirst[language]} stagger={stagger()} />
                        <AnimateText extraSpacing text={this.strings.headingSecond[language]} stagger={stagger()} />
                    </Heading>
                    <Body>
                        <AnimateText text={this.strings.body[language]} stagger={stagger()} />
                    </Body>
                </Content>
            </Container>
        )
    }
}

Home.propTypes = {
    language: PropTypes.string.isRequired,
}

export default Home
