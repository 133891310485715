import { createStatic } from 'styled-components-breakpoint'

const breakpoints = createStatic({
    tablet: 737,
    desktop: 1025,
    desktopLarge: 1440,
})

export const tablet = breakpoints.tablet
export const desktop = breakpoints.desktop
export const desktopLarge = breakpoints.desktopLarge

export const color = {
    white: '#FFFFFF',
    neutralLighter: '#E5E5E5',
    neutral: '#CCD2D0',
    neutralDarkest: '#2F2F2F',
}

export const colors = color

export const font = {
    heading: "'HaikuS', sans-serif",
    body: "'HaikuP', sans-serif",
    tiny: '12px',
    small: '14px',
    normal: '16px',
    medium: '20px',
    large: '30px',
    larger: '40px',
    largest: '60px',
    huge: '90px',
    weight: {
        normal: 'normal',
        medium: '500',
        bold: 'bold',
    },
}

export const cols = (cols, offset = 0) => {
    return `calc((100vw - 144px) / 8 * ${cols}${offset ? ` + ${offset}px` : ''})`
}
