import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { PATH_MEDIA } from '../constants'

const Container = styled.div`
    position: absolute;
    z-index: 0;
    bottom: ${({ position }) => (position === 'bottom' ? 0 : 'auto')};
    top: ${({ position }) => (position === 'top' ? 0 : 'auto')};
    width: 100%;
`

const Image = styled.img`
    display: block;
    position: relative;
    width: 100%;
    opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
    transition: opacity 0.6s;
`

class WorkContentBackground extends React.Component {
    state = {
        isLoaded: false,
    }
    componentDidMount() {
        this.loadTimer = setTimeout(() => {
            this.setState({ isLoaded: true })
        }, 600)
    }
    componentWillUnmount() {
        clearTimeout(this.loadTimer)
    }
    render() {
        const { data } = this.props
        const { isLoaded } = this.state
        const { position } = data
        return (
            <Container position={position}>
                <Image src={PATH_MEDIA + '/' + data.filename} isLoaded={isLoaded} />
            </Container>
        )
    }
}

WorkContentBackground.propTypes = {
    data: PropTypes.object.isRequired,
    background: PropTypes.string.isRequired,
}

export default WorkContentBackground
