import React from 'react';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import {
  metaTags,
  title,
} from '../helpers';
import {
  cols,
  desktop,
  font,
  tablet,
} from '../styles';
import AnimateText from './AnimateText';

const Container = styled.div`
    padding: 24px;
    padding-top: 9vh;
    ${tablet`
        position: fixed;
        top: 0;
        right: 72px;
        width: ${cols(6)};
        height: 100vh;
        padding: 0;
    `};
`

const Content = styled.div`
    padding: 0;
`

const Heading = styled.h1`
    font-family: ${font.heading};
    font-size: ${font.large};
    line-height: 1.2;
    position: relative;
    z-index: 1;
    margin-bottom: 24px;
    ${tablet`
        font-size: ${font.largest};
        line-height: 1.1;
        position: absolute;
        top: 50vh;
        left: ${cols(3)};
        width: ${cols(3)};
        margin-top: -12px;
        margin-bottom: 0;
    `};
    ${desktop`
        font-size: ${font.huge};
        line-height: 1;
        margin-top: -18px;
    `};
`

const Body = styled.div`
    font-size: ${font.normal};
    line-height: 1.5;
    ${tablet`
        font-size: ${font.normal};
        line-height: 1.5;
        position: absolute;
        top: 50vh;
        left: 0;
        width: ${cols(2)};
    `};
    ${desktop`
        font-size: ${font.medium};
    `};
`

const Section = styled.div`
    &:not(:last-child) {
        margin-bottom: 2vh;
    }
`

const Address = styled.div`
    margin-top: 4vh;
`

const propTypes = {
    language: PropTypes.string.isRequired,
}

class Home extends React.Component {
    strings = {
        title: { en: 'Contact', et: 'Kontakt' },
        headingFirst: { en: 'Hi.', et: 'Tere.' },
        headingSecond: { en: 'Hello.', et: 'Tere.' },
        noblessner: { en: 'Port Noblessner', et: 'Noblessneri sadam' },
        tallinn: { en: 'Tallinn, Estonia', et: 'Tallinn 10414' },
        directions: { en: 'Get directions', et: 'Vaata kaardilt' },
    }

    render() {
        const { language } = this.props

        let s = 1
        const stagger = () => s++

        return (
            <Container>
                <Helmet>
                    {metaTags({
                        language,
                        title: title(this.strings.title[language]),
                    })}
                </Helmet>
                <Content>
                    <Heading>
                        <AnimateText extraSpacing text={this.strings.headingFirst[language]} stagger={stagger()} />
                        <AnimateText extraSpacing text={this.strings.headingSecond[language]} stagger={stagger()} />
                    </Heading>
                    <Body>
                        <Section>
                            <AnimateText text="Kaupo Kõrv" stagger={stagger()} />
                            <AnimateText text="kaupo@haiku.ee" stagger={stagger()} />
                        </Section>
                        <Section>
                            <a href="https://facebook.com/haiku.ee" target="_blank" rel="noopener noreferrer">
                                <AnimateText text="Facebook" stagger={stagger()} />
                            </a>
                            <a href="https://instagram.com/itshaiku" target="_blank" rel="noopener noreferrer">
                                <AnimateText text="Instagram" stagger={stagger()} />
                            </a>
                        </Section>
                        <Address>
                            <Section>
                                <AnimateText text="Peetri 11" stagger={stagger()} />
                                <AnimateText text={this.strings.noblessner[language]} stagger={stagger()} />
                                <AnimateText text={this.strings.tallinn[language]} stagger={stagger()} />
                            </Section>
                            <Section>
                                <a href="https://goo.gl/maps/pkiFcxkDcoB2" target="_blank" rel="noopener noreferrer">
                                    <AnimateText text={this.strings.directions[language]} stagger={stagger()} />
                                </a>
                            </Section>
                        </Address>
                    </Body>
                </Content>
            </Container>
        )
    }
}

Home.propTypes = propTypes

export default Home
