import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { desktop } from '../styles'

const Container = styled.button`
    position: fixed;
    z-index: 3000;
    right: 12px;
    bottom: 12px;
    width: 72px;
    height: 72px;
    background: none;
    border: 0;
    cursor: pointer;
    transition: opacity 0.3s;
    &:active,
    &:focus {
        outline: none;
    }
    ${desktop`
        display: none;
    `};
`

const Line = styled.div`
    position: absolute;
    top: 0;
    left: 27px;
    width: 21px;
    height: 2px;
    background: ${({ color }) => color};
    transition: background 0.6s;
    &:nth-child(1) {
        top: 28px;
        transform: rotate(45deg);
    }
    &:nth-child(2) {
        top: 42px;
        transform: rotate(-45deg);
    }
`

const Next = ({ color, handleClick }) => (
    <Container onClick={handleClick}>
        <Line color={color} />
        <Line color={color} />
    </Container>
)

Next.propTypes = {
    handleClick: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
}

export default Next
