import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { paths } from '../config'
import { LANGUAGE_EN, LANGUAGE_ET } from '../constants'
import { languagePath } from '../helpers'
import { cols, font, desktop, tablet } from '../styles'

import AnimateText from './AnimateText'

const Nav = styled.div`
    font-family: ${font.heading};
    position: absolute;
    top: calc(9vh + 6px);
    left: 0;
    min-width: 100%;
    ${tablet`
        top: 50vh;
        padding: 0;
    `};
`

const NavItem = styled.div`
    font-size: ${font.large};
    text-align: center;
    padding-left: 24px;
    ${tablet`
        font-size: ${font.medium};
        text-align: left;
        padding: 0;
    `};
    ${desktop`
        font-size: ${font.large};
    `};
    a {
        white-space: nowrap;
        text-decoration: none;
        display: block;
        padding: 0 0 12px;
        opacity: ${({ isActive }) => (isActive ? '0.2' : 'inherit')};
        transition: color 0.6s, opacity 0.15s;
        ${tablet`
            opacity: inherit;
        `};
    }
`

// const SubNav = styled.div`
//     position: absolute;
//     top: 0;
// `

// const SubNavItem = styled.div`
//     a {
//         padding: 0;
//     }
// `

const Language = styled.div`
    font-size: ${font.medium};
    position: absolute;
    left: 0;
    bottom: 30vh;
    min-width: 50%;
    padding-left: 24px;
    ${tablet`
        font-size: ${font.normal};
        line-height: 1;
        bottom: 9vh;
        padding: 0;
        min-width: 100%;
    `};
    ${desktop`
        font-size: ${font.medium};
    `};
    a {
        white-space: nowrap;
        text-decoration: none;
        display: block;
        transition: color 0.6s, opacity 0.15s;
    }
`

const Container = styled.div`
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(1);
    transition: background 0.6s, opacity 0.3s, transform 0.3s;
    background: ${({ foregroundColor }) => foregroundColor};
    ${({ isHidden }) =>
        isHidden &&
        `
            opacity: 0;
            transform: scale(1.1);
            pointer-events: none;
        `}
    ${tablet`
        display: block;
        left: 72px;
        width: ${cols(2)};
        background: none;
        opacity: 1;
        pointer-events: auto;
        transform: scale(1);
    `};
    ${NavItem}, ${Language} {
        a {
            color: ${({ backgroundColor }) => backgroundColor};
            ${tablet`
                color: ${({ foregroundColor }) => foregroundColor};
                opacity: ${({ keepVisible }) => (keepVisible ? 1 : 0.2)};
                &:hover {
                    opacity: 1;
                }
                &:active {
                    opacity: 0.7;
                    transition: none;
                }
            `};
        }
    }
`

class Sidebar extends React.Component {
    strings = {
        home: { en: 'Haiku', et: 'Haiku' },
        about: { en: 'Studio', et: 'Stuudio' },
        work: { en: 'Work', et: 'Tööd' },
        start: { en: 'Work with us', et: 'Protsess' },
        contact: { en: 'Contact', et: 'Kontakt' },
        lang: { en: 'eesti keeles', et: 'in English' },
    }

    switchLanguagePath() {
        const { language, location } = this.props
        const newLanguage = language === LANGUAGE_ET ? LANGUAGE_EN : LANGUAGE_ET
        return languagePath(newLanguage, location.pathname)
    }

    isActive(path, exact) {
        const { language, location } = this.props
        if (exact) {
            return location.pathname === languagePath(language, path)
        }
        return location.pathname.indexOf(languagePath(language, path)) > -1
    }

    render() {
        const { language, foregroundColor, backgroundColor, keepVisible, isHidden } = this.props

        let s = 1
        const stagger = () => s++

        return (
            <Container
                foregroundColor={foregroundColor}
                backgroundColor={backgroundColor}
                keepVisible={keepVisible}
                isHidden={isHidden}
            >
                <Nav>
                    <NavItem isActive={this.isActive(paths.root, true)}>
                        <Link to={languagePath(language, paths.root)}>
                            <AnimateText text={this.strings.home[language]} stagger={stagger()} />
                        </Link>
                    </NavItem>
                    <NavItem isActive={this.isActive(paths.about)}>
                        <Link to={languagePath(language, paths.about)}>
                            <AnimateText text={this.strings.about[language]} stagger={stagger()} />
                        </Link>
                    </NavItem>
                    <NavItem isActive={this.isActive(paths.work)}>
                        <Link to={languagePath(language, paths.work)}>
                            <AnimateText text={this.strings.work[language]} stagger={stagger()} />
                        </Link>
                    </NavItem>
                    <NavItem isActive={this.isActive(paths.contact)}>
                        <Link to={languagePath(language, paths.contact)}>
                            <AnimateText text={this.strings.contact[language]} stagger={stagger()} />
                        </Link>
                    </NavItem>
                </Nav>
                <Language>
                    <Link to={this.switchLanguagePath()}>
                        <AnimateText text={this.strings.lang[language]} stagger={stagger()} />
                    </Link>
                </Language>
            </Container>
        )
    }
}

Sidebar.propTypes = {
    backgroundColor: PropTypes.string.isRequired,
    foregroundColor: PropTypes.string.isRequired,
    isHidden: PropTypes.bool.isRequired,
    keepVisible: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
}

export default Sidebar
