import React from 'react'

import { colors } from './styles'

export const hexToRgbA = (hex, opacity = 1) => {
    var c
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('')
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = '0x' + c.join('')
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')'
    }
    throw new Error('Bad Hex')
}

export const generateKeyFromValue = (value) => {
    if (!value) return 0
    var hash = 0,
        i,
        chr
    if (value.length === 0) return hash
    for (i = 0; i < value.length; i++) {
        chr = value.charCodeAt(i)
        hash = (hash << 5) - hash + chr
        hash |= 0 // Convert to 32bit integer
    }
    return Math.abs(hash)
}

export const generateRandomKey = () => {
    return generateKeyFromValue('randomKey' + Math.random())
}

export const languageFromPath = (path) => {
    return path.substring(1, 3) === 'et' ? 'et' : 'en'
}
export const languagePath = (language, path) => {
    return (language === 'et' ? '/' + language : '') + removeLanguageFromPath(path)
}

export const removeLanguageFromPath = (path) => {
    return path.substring(1, 3) === 'et' ? path.substring(3) : path
}

export const title = (title = null, disableSuffix = false) => {
    return title ? title + (disableSuffix ? '' : ' – Haiku') : 'Haiku'
}

export const metaTags = ({ language, title, description, themeColor }) => {
    return [
        <html key={1} lang={language} />,
        <meta key={2} property="og:title" content={title} />,
        <meta key={3} property="og:description" content={description} />,
        <meta key={4} name="twitter:title" content={title} />,
        <meta key={5} name="twitter:description" content={description} />,
        <title key={6}>{title}</title>,
        <meta key={7} name="description" content={description} />,
        <meta key={8} name="theme-color" content={themeColor || colors.neutralLighter} />,
    ]
}
